@media (orientation: portrait) {
  .graphic {
    margin-left: 3rem;
    margin-right: 3rem;
    width: 80%;
  }

  .myhelp {
    max-width: 90vw;
  }

  .portrait {
    width: 100%;
  }

  .no-portrait {
    display: none;
  }

  .text {
    width: 85vw;
  }

  .text p {
    font-size: 1rem !important;
  }
}

@media (orientation: landscape) {
  .graphic {
    width: 20vw;
    height: 20vw;
  }

  .myhelp {
    max-width: 750px;
  }

  .portrait {
    width: 20vw;
    height: 20vw;
  }

  .text {
    width: 45vw;
    font-size: 1rem !important;
  }
}

hr {
  height: 50px;
  background-color: gainsboro;
}

.myhelp {
  color: white;
  background-color: transparent;
  padding: 1rem;
}

.portrait {
  border: 1px solid rgba(209, 213, 219, 0.3);
  border-radius: 10px;
  background-color: transparent;
  box-shadow: #333 1vh 1vh 1vh inset;
  opacity: 0.9;
}
