@-ms-viewport {
  width: device-width;
}
/*Ref: https://css-tricks.com/snippets/html/responsive-meta-tag/*/

@media screen {
  .noscreen {
    display: none;
    visibility: hidden;
  }
}

@media print {
  .noprint {
    display: none;
    visibility: hidden;
  }
}

a,
article,
button,
caption,
div,
.h1,
.h2,
.h3,
p,
li,
option,
section,
select,
td,
th {
  font-family: "Segoe UI", Roboto, Inter, "San Francisco", Tahoma, Geneva,
    Verdana, sans-serif;
}

/*///////// PORTRAIT ////////////////*/

@media (orientation: portrait) {
  a,
  article,
  caption,
  div,
  li,
  option,
  p,
  section,
  select,
  td,
  textarea,
  th {
    font-size: 1rem;
  }

  .btn-next-page {
    /* used in guided tour */
    position: absolute;
    right: 1px;
    top: 90vh;
  }
  .btn-next-step {
    /* used in techniques */
    width: 90vw;
  }

  .flex-direction {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .gt-caption {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .gt-image {
    max-width: 90vw;
  }

  .h1 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }
  .h2 {
    font-size: 1.5rem;
  }
  .h3 {
    font-size: 1.2rem;
  }

  .header-logo {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 70vw;
  }

  .heading-major {
    font-size: 2rem;
    padding: 1rem;
    min-width: 100vw !important;
    width: 100%;
  }

  .helpMenu-card {
    margin: 1rem;
    padding: 1rem 1rem 0 1rem;
    width: 40vw;
  }

  .helpMenu-image {
    width: 28vw;
  }

  .home-card {
    padding: 1vw;
    width: 90vw;
    min-height: 76vh;
  }

  .home-card-title {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.5rem;
    text-align: center;
  }

  .home-card-image {
    width: 90vw;
  }

  .home-card-text {
    font-size: medium;
    padding: 8px;
  }

  .home-card-btn {
    font-size: 1rem;
    margin: 0.5rem;
    padding: 0.5rem;
  }

  .home-para1 {
    font-size: 1rem;
  }

  .input {
    font-size: 1.2rem !important;
    margin: 10px;
    padding: 10px;
    width: 80vw;
  }

  .label {
    padding: 3vmin;
    margin: 3px;
  }

  .login-label {
    font-size: 1.1rem;
    width: 20rem;
  }

  .image-methodB {
    max-height: 25vw;
    max-width: 25vw;
  }

  p {
    margin: 0 auto 1rem auto;
    padding: 0rem 1rem 0rem 1rem;
    max-width: 700px;
    text-align: left;
  }

  .page-text {
    padding: 2rem !important;
    margin: none;
    max-width: 750px;
    background-color: transparent;
  }

  .personal-card {
    width: 80vw;
    margin: 0 5vw 0 5vw;
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    border-width: thin;
    border-color: gray;
  }

  .photo-full {
    width: 100%;
    max-height: 25%;
  }

  .photo-half {
    max-width: 90vw;
    margin: 1rem;
  }
  .photo-10pcnt {
    max-height: 15vh;
    margin: 1rem;
  }

  .results-graphic {
    width: 45vmax;
  }
  .results-image {
    width: 45vmax;
  }

  .rwButton,
  .rwButton-disabled {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.2rem;
    font-size: 1rem;
  }

  .table-grid td,
  .table-grid th {
    font-size: 0.8rem !important;
    padding: 0.5rem !important;
  }

  .text-half {
    display: inline-block;
    margin: 1rem 1rem 1rem 1rem !important;
    padding: 1rem;
    max-width: 90vw;
    text-align: left;
  }

  .width5015 {
    width: 70vw;
  }  
  

  .width9030 {
    width: 90vw;
  }

  .width9050 {
    width: 90vw;
  }

  .width100 {
    max-width: 100vw !important;
  }
}

/*///////// LANDSCAPE ////////////////*/

@media (orientation: landscape) {
  a,
  article,
  caption,
  div,
  li,
  option,
  p,
  section,
  select,
  td,
  textarea,
  th {
    font-size: 1rem;
  }

  .btn-next-page {
    position: absolute;
    right: 5px;
    top: 50vh;
  }
  .btn-next-step {
    /* used in techniques */
    width: 50vw;
  }

  .flex-direction {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .gt-caption {
    width: 100%;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .gt-image {
    max-width: 90vw;
  }

  .h1 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }
  .h2 {
    font-size: 1.5rem;
  }
  .h3 {
    font-size: 1.2rem;
  }

  .header-logo {
    margin: 1rem;
    width: 30vw;
  }

  .heading-major {
    font-size: 1.5rem !important;
    font-weight: 600;
    padding: 1rem !important;
    width: 100% !important;
  }

  .helpMenu-card {
    margin: 1rem;
    padding: 1rem 1rem 0 1rem;
    width: 19vw;
  }

  .helpMenu-image {
    width: 14vw;
  }

  .home-card {
    border-radius: 4px;
    border-color: gray;
    border-width: thin;
    border-style: outset;
    padding: 1vw;
    width: 20vw;
    min-height: 650px;
    background-color: white;
    position: relative;
  }

  .home-card-title {
    font-size: 1.3rem;
    font-weight: 700;
    padding: 0.5rem;
    text-align: center;
  }

  .home-card-image {
    width: 18vmax;
  }

  .home-card-text {
    font-size: medium;
    font-weight: 600;
    padding: 8px;
    text-align: left;
    max-width: 28vmax;
    margin-bottom: 35px;
  }

  .home-card-btn {
    font-size: 1rem;
    font-weight: 600;
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    background-color: gold;
    border-radius: 4px;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .home-para1 {
    font-size: 1.1rem;
  }

  .input {
    font-size: 1.5rem !important;
    padding: 0.5rem;
    width: 30rem;
  }

  .label {
    padding: 3vmin;
    margin: 3px;
  }
  .login-label {
    font-size: 1.3rem;
    padding: 3vmin;
    margin-bottom: -10px;
  }

  .image-methodB {
    max-height: 10vw;
    max-width: 10vw;
  }

  p,
  ul,
  ol {
    margin-bottom: 0.7rem;
    max-width: 750px;
    text-align: left !important;
  }

  .page-text {
    padding: 2rem !important;
    margin: none;
    max-width: 750px;
    background-color: transparent;
    text-align: left;
  }

  .photo-full {
    border-style: inset;
    border-color: gray;
    height: 35vh;
  }
  .photo-half {
    max-width: 20vw;
    margin: 0px auto 5px auto !important;
  }

  .photo-10pcnt {
    max-height: 25vh;
    margin: 1rem;
  }

  .results-graphic {
    width: 35vmax;
  }
  .results-image {
    width: 25vmax;
    margin-right: 5rem;
  }

  .rwButton,
  .rwButton-disabled {
    font-size: 1.3rem;
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .setup-select {
    margin: 0 auto 0 auto;
    max-width: 50%;
    width: 500px;
  }

  .table-grid td,
  .table-grid th {
    font-size: 1rem !important;
    padding: 0.5rem !important;
  }

  .text-half {
    margin: 1rem !important;
    padding: 1rem;
    max-width: 40vw;
    text-align: left;
  }

  .width5015 {
    width: 15vw;
  }  
  
  .width9030 {
    width: 30vw;
  }

  .width9050 {
    width: 50vw;
  }

  .width100 {
    width: 100vw;
  }
}

/* ///////////////// portrait and landscape //////////////////////////////////////////7 */

.alert {
  /* this is the background of the alert */
  &-inner {
    /* body wrapper of the alert */
    background-color: red;
    color: white;
  }
  &-title {
    /* title of the alert */
  }
  &-text {
    /* text describer of the alert */
  }
  &-input {
    /* the prompt input field */
  }
  &-buttons {
    /* button wrapper of the alert */
    &_ok {
      /* ok button of the alert */
    }
    &_cancel {
      /* cancel button of the alert */
    }
  }
}

.bg-black {
  background-color: black;
}
.bg-blue {
  background-color: blue;
}

.bg-crimson {
  background-color: crimson;
}
.bg-cyan {
  background-color: cyan !important;
}

.bg-darkred {
  background-color: darkred;
  color: white;
}

.bg-darkslateblue {
  background-color: darkslateblue;
}
.bg-darkviolet {
  background-color: darkviolet;
}
.bg-dodgerblue {
  background-color: dodgerblue;
}

.bg-gainsboro {
  background-color: gainsboro;
}

.bg-green {
  background-color: green;
}
.bg-ivory {
  background-color: ivory;
}
.bg-lightcyan {
  background-color: lightcyan;
  border-radius: 10px;
  margin: 10px;
}

.bg-lightgreen {
  background-color: lightgreen;
}
.bg-lightsteelblue {
  background-color: lightsteelblue;
}
.bg-orange {
  background-color: orange;
}
.bg-orangered {
  background-color: orangered;
}

.bg-red {
  background-color: red;
}
.bg-tw-blue {
  background-color: #264971;
}
.bg-white {
  background-color: white;
}
.bg-yellow {
  background-color: yellow !important;
}

.border-round {
  border-radius: 10px;
}
.border-thin {
  border-width: 1px;
  border-style: solid;
  border-color: silver;
}

.btn-next-step {
  /* used in techniques */
  background-color: paleturquoise !important;
  font-weight: 700;
}

.btn-pwd-show-hide {
  width: 2vmax;
  height: 2vmax;
  border-radius: 20px;
  border: 1px outset silver;
}

.btn-selected {
  border-radius: 10px;
  flex-direction: "column";
  background-color: red !important  ;
  font-weight: bold;
  text-align: center;
}

.center {
  display: flex;
  margin: 1rem auto 1rem auto;
}

.color-black {
  color: black !important;
}

.color-whitesmoke {
  color: whitesmoke;
}

.color-white {
  color: white;
}

.configure-highlow-selected {
  background-color: silver;
  border-radius: 10px;
  margin: 0.5rem;
  padding: 0.5rem;
}

.dont-underline {
  text-decoration: none;
}

.error-msg {
  font-size: larger;
  font-weight: bold;
  background-color: crimson;
  color: white;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: darkred;
}

.fill-page {
  min-height: 100vh;
  padding-bottom: 5rem;
}

.flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}

.flex-bunch {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-col {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row;
}

.flex-item-center {
  align-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
}
.flex-item-left {
  align-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  justify-self: flex-start;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-start {
  align-items: flex-start;
  align-self: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-self: flex-start;
  justify-content: flex-start;
}

.flex-item-top {
  align-items: flex-start;
  align-self: flex-start;
  align-content: stretch;
}

.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.font500 {
  font-weight: 500;
}
.font600 {
  font-weight: 600;
}

.font-larger {
  font-size: larger !important;
}

.font-xx-small {
  font-size: xx-small;
}
.font-x-large {
  font-size: x-large;
}

.gap1 {
  grid-gap: 1rem;
}
.gap2 {
  grid-gap: 2rem;
}
.gap3 {
  grid-gap: 3rem;
}
.gap-half {
  grid-gap: 0.5rem;
}

.grid {
  display: grid;
}

.gt-caption {
  background-color: gainsboro;
  font-size: larger;
  font-weight: bold;
  padding-top: 1rem;
  vertical-align: bottom !important;
}

.h1 {
  font-weight: bold;
  text-align: center;
}
.h2 {
  font-weight: bold;
}
.h3 {
  font-weight: bold;
}

.header-btns {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 100;
}

.heading-major {
  background-color: gainsboro;
  text-align: center;
}

.helpMenu-card {
  border-color: whitesmoke;
  border-style: outset;
  border-radius: 10px;
  border-width: thick;
  font-weight: 600;
}

.hide {
  visibility: hidden;
}

.home-card {
  border-radius: 4px;
  border-color: gray;
  border-width: thin;
  border-style: outset;
  background-color: white;
  position: relative;
}

.home-card-title {
  font-weight: 700;
  text-align: center;
}

.home-card-text {
  font-weight: 600;
  text-align: left;
}

.home-card-btn {
  font-weight: 600;
  text-align: center;
  background-color: gold;
  border-radius: 4px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.ib {
  display: inline-block;
}

.image-methodB {
  border-radius: 50px;
}

.inline {
  display: inline;
}

.label {
  font-family: "Trebuchet MS", sans-serif;
}

.long-text-landscape {
  margin-top: 0rem !important;
}

.margbott1 {
  margin-bottom: 1rem;
}
.margbott3 {
  margin-bottom: 3rem;
}
.margbott10 {
  margin-bottom: 10rem;
}
.margbott-2px {
  margin-bottom: -3px;
}

.margin0 {
  margin: 0px !important;
}
.margin1 {
  margin: 1rem !important;
}
.margin2 {
  margin: 2rem !important;
}
.margin5 {
  margin: 5rem !important;
}
.margin10 {
  margin: 10rem !important;
}

.margleft1 {
  margin-left: 1rem;
}
.margin-left5R {
  margin-left: 5rem;
}

.margtop5 {
  margin-top: 5rem;
}
.margtop10 {
  margin-top: 10rem;
}
.margtop20 {
  margin-top: 20rem;
}

.mm-image {
  width: 100%;
}

ol,
ul {
  text-align: left;
}
p {
  text-align: left;
}

.paddbott1 {
  padding-bottom: 1rem;
}
.paddbott2 {
  padding-bottom: 2rem;
}
.paddbott3 {
  padding-bottom: 3rem;
}

.paddbott10 {
  padding-bottom: 10rem;
}

.padding1 {
  padding: 1rem;
}

.padding2 {
  padding: 2rem;
}
.padding3 {
  padding: 3rem;
}

.padding-half {
  padding: 0.5rem;
}

.para-center {
  margin: 0 auto;
}

.personal-label {
  text-align: left;
}

.photo-half {
  border-radius: 10px;
  border-style: inset;
  border-color: gray;
}

.radio-big {
  width: 5vmax;
  height: 5vmax;
}

.right {
  display: flex;
  margin: 1rem 5rem 1rem auto;
}
.right-bottom {
  display: flex;
  margin: 45vh 1rem 1rem auto; /* assume we set div height to 200 */
}

.smaller {
  font-size: smaller;
}

.table-grid td,
.table-grid th,
.table-grid tr {
  border: 1px solid gainsboro !important;
  text-align: left !important;
  vertical-align: top !important;
}

.text-center {
  text-align: center;
}

.text-half {
  background-color: white;
  border-radius: 10px;
  color: navy;
  font-weight: 600;
}
.text-left {
  text-align: left;
}

.rwButton {
  border: none;
  background-color: transparent;
  border-radius: 4px;
  box-shadow: 1px 1px #999;
  color: midnightblue;
  cursor: pointer;
  outline: none;
  text-align: center;
  font-weight: 600;
}

.rwButton-disabled {
  color: dimgray;
  border: none;
}

.rwButton:active {
  background-color: orange;
  transform: translateY(4px);
}

.rwButton:hover {
  background-color: yellow;
  color: black;
}

/* .rwButton:visited {
  background-color: red;
  color: white;
}
 */
.rwButtonVisited {
  background-color: red;
  color: white;
}

.vert-top {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  /*   justify-self: flex-start;
  justify-content: flex-start;
  justify-items: flex-start; */
  vertical-align: top;
}

.width20 {
  width: 20rem;
}
